$padding_section_content: 40px;
$header-margin-top: 85px;

body {
	font-family: Raleway;
	font-weight: normal;
	font-size: 15px;
	font-weight: 200;
	line-height: 25px;
	background-color: #f8f4f4;
}

.main {
	padding-left: 0;
	padding-right: 0;
}

#content-pages {
	margin-top: $header-margin-top;

}



#header-page {
	max-height: $header-margin-top;
	height: $header-margin-top;
}

.navbar {
	margin-bottom: 0;
}


// FORMS
.custom_wpcf7{
	.wpcf7{
		.form-control{
			font-family: Montserrat;
			background-color: transparent;
			margin: 5px 0;
			color:#fff;
			font-weight: 100;
			font-size: 10px;
			border-radius: 0;
			border-color: rgba(255, 255, 255, 0.2);
		}

		::-webkit-input-placeholder,:-moz-placeholder,::-moz-placeholder,:-ms-input-placeholder {
			color:#fff;
			font-weight: 600;
			font-size: 16;
		}

		.btn-default {
			font-family: Montserrat;
			font-weight: 100;
			font-size: 10px;
			color: #fff;
			background-color: transparent;
			border-color: #fff;
			border-radius: 0;
			padding: 11px 35px;
			border-width: 1px;
		}
		.btn-default:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}
}

/* Generales */
h1,h2,h3,h4 {
	text-transform: uppercase;
	font-family: Raleway;
}

h1 {
	font-weight: bold;
}

h3 {
	font-size: 20px;
	font-weight: 600;
}

h4 {
	font-size: 17px;
	font-weight: 600;
}

h5 {
	font-size: 17px;
	font-weight: normal;
}

.grid-item {
	width: 380px;
	padding: 0 20px;
	text-align: justify;
}

.grid-item--width2 {
	width: 400px;
}

.list_lower_alpha{
	list-style-type: lower-alpha;
}

/* SHORTCODE CUBE */
.cubo_child {
	padding: 15px;
	border-color: rgba(255, 255, 255, 0.7);
	border-width: 1px;
	border-style: solid;;
}

.cubo_child:hover{
	opacity: 0.7;
}

.cube_title h5 {
	padding-top: 32px;
	font-weight: 600;
	font-size: 24px;
	text-align: center;
}

.cube_content {
	font-size: 11px;
	line-height: 13px;
	text-align: justify;
	margin: 20px 0;
	font-weight: 600;
	letter-spacing: 1px;
}

/* SHORTCODE NAVIGATION SUPERSLIDE */

.slides-navigation a{
	font-size: 26px;
	font-weight: bold;
	font-size: 26px;
	margin: 0 25px;
	text-shadow: 0 1px 1px #000;
	text-align: center;
	display: block;
}

.slides-navigation a:hover{
	opacity: 0.5;
}

.slides-navigation a.prev	{
	height: 66px;
	width: 23px;
	background-image: url('../images/arrow_left.png');
}

.slides-navigation a.next {
	height: 66px;
	width: 23px;
	background-image: url('../images/arrow_right.png');
}

.slides-pagination a {
	border-color:#fff;
}
.slides-pagination a.current {
	background-color: #fff;
}
/* */
@media screen and (max-width: 892px) {
	.background-responsive{
		background-image: none!important;
	}
}
