@font-face {
	font-family: Raleway;
	font-weight:bold;
	src: url('../fonts/Raleway-Bold.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight:600;
	src: url('../fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight:100;
	src: url('../fonts/Raleway-Light.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight:300;
	src: url('../fonts/Raleway-Medium.ttf') format('truetype');
}

@font-face {
	font-family: Raleway;
	font-weight:normal;
	src: url('../fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Montserrat;
	font-weight:normal;
	src: url('../fonts/Montserrat-Regular.otf');
}

@font-face {
	font-family: Montserrat;
	font-weight:100;
	src: url('../fonts/Montserrat-Light.otf');
}

@font-face {
	font-family: Montserrat;
	font-weight:600;
	src: url('../fonts/Montserrat-SemiBold.otf');
}

@font-face {
	font-family: Montserrat;
	font-weight:bold;
	src: url('../fonts/Montserrat-Bold.otf');
}
