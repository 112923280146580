footer {
  min-height: 30px;
  background-color: #143956;
  color: #fff;

  .textwidget {
    font-size: 16px;
    margin: 5px 0;
    font-family:  Montserrat;
    font-weight: 100;
    font-size: 10px;
    letter-spacing: 1px;
  }
}
